import { Directive, ViewContainerRef, TemplateRef, Input, OnInit, OnChanges, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "../../service/authorization/authorization.service";
import { NavigationOrganizationRestriction } from "../../models/navigation/navigation-organization-restriction";
import Utils from "../../utilities/utils";

@Directive({
  selector: "[permissionDirective]",
})
export class PermissionDirective implements OnChanges, OnDestroy {
  private _userFeatureAccessToAuthorize = 0;
  private _orgAccessToAuthorize = 0;
  private _featureFlag = true;
  private _navigationOrganizationRestriction = 0;

  @Input() set permissionDirective(value: number) {
    this._userFeatureAccessToAuthorize = value;
  }
  @Input() set orgPermission(value: number) {
    this._orgAccessToAuthorize = value;
  }
  @Input() set featureFlag(value: boolean) {
    this._featureFlag = value;
  }
  @Input() set navigationOrganizationRestriction(value: NavigationOrganizationRestriction) {
    this._navigationOrganizationRestriction = value;
  }
  //e2e test to see if value shows up

  private _hasBeenRendered = false;
  private _subscription: Subscription[] = [];

  constructor(
    private _container: ViewContainerRef,
    private _template: TemplateRef<any>,
    private authService: AuthService
  ) {}

  public ngOnChanges(): void {
    this.evaluatePermissions();
  }

  public evaluatePermissions(): void {
    this._subscription.push(
      this.authService
        .isPermitted(
          this._userFeatureAccessToAuthorize,
          this._orgAccessToAuthorize,
          this._featureFlag,
          this._navigationOrganizationRestriction
        )
        .subscribe((canSeeSection: boolean): void => {         
          if (canSeeSection && !this._hasBeenRendered) {
            this._container.createEmbeddedView(this._template);
            this._hasBeenRendered = true;
          } else if (!canSeeSection && this._hasBeenRendered) {
            this._container.clear();
            this._hasBeenRendered = false;
          }
        })
    );
  }

  public ngOnDestroy(): void {
    if (Utils.isArrayWithLength(this._subscription)) {
      this._subscription.forEach((s) => s.unsubscribe());
    }
  }
}
