import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PreLoginComponent } from "./pre-login.component";

const LoginRoutes: Routes = [
  //Put paths here
  {
    path: "",
    component: PreLoginComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(LoginRoutes)],
  providers: [],
})
export class PreLoginRoutesModule {}
