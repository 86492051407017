import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LDFlagSet, LDFlagValue, LDClient, initialize } from 'launchdarkly-js-client-sdk';
import { Environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})

export class FeatureFlagService implements OnDestroy {
  public client: LDClient;
  public flags: LDFlagSet;

  private _flagChange$: BehaviorSubject<Object> = new BehaviorSubject<any>(
    null
  );
  flagChange$: Observable<Object> = this._flagChange$.asObservable();
  
  async ngOnDestroy() {
    await this.client.close();
  }
  
  private initialize() {
    const anonymousUserContext = {
      kind: 'organizationId',
      anonymous: true
    };
    this.client = initialize(
      Environment.LAUNCH_DARKLY_CLIENT_ID,
      anonymousUserContext
    );
  }
  
  public initFlag(flagKey: string, defaultValue: LDFlagValue): Observable<any> {
    try {
      this.initialize();
      this.client.waitUntilReady().then(() => {
        this.setFlag(flagKey, defaultValue);
        this.client.on(`change:${flagKey}`, (flagValue) => {
          this.setFlag(flagKey, flagValue);
        });
      });
    } catch (err) {
      this.setFlag(flagKey, defaultValue);
      throw err;
    }
    return this.flagChange$;
  }
  
  setFlag(flagKey: string, defaultValue: LDFlagValue) {
    this._flagChange$.next(this.client.variation(flagKey, defaultValue));
  }
}