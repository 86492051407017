
import { Organization, SessionDataResponse, SystemNotification, User } from './../../interfaces/IUser';
import { getDomain } from 'tldts';
import { SessionStateService } from "./session-state.service";
import { Response } from "../../models/http/response";
import { SessionUser } from "./../../models/user/user";
import { Injectable } from "@angular/core";
import { HttpService } from "../http/http.service";
import { map, Observable, of, forkJoin, catchError, share } from "rxjs";
import { CookieOptions, CookieService, SameSite } from "ngx-cookie-service";
import { COOKIES, AUTHENTICATION, LOCAL_STORAGE } from "../../utilities/constants";
import Utils from '../../utilities/utils';
import { EnvironmentService } from '../environment/environment.service';
import { LocalStorageService } from '../localstorage/localstorage.service';
import { HttpErrorResponse } from '@angular/common/http';

const cookieOpts: CookieOptions = {
  expires: 3600,
  path: "/",
  domain: getDomain(window.location.origin),
  sameSite: 'Lax'
}

const twelveHoursInMs = 1000 * 60 * 60 * 12;
@Injectable({ providedIn: "root" })
export class UserService {

  constructor(
    private httpService: HttpService,
    private cookieService: CookieService,
    private sessionState: SessionStateService,
    private environmentService: EnvironmentService,
    private localStorageService: LocalStorageService,
  ) { }

  private getUserInformation(orgId?: number): Observable<SessionDataResponse> {
    const sessionData = this.localStorageService.getItem<User>(LOCAL_STORAGE.SESSION_DATA);
    if (orgId && orgId > 0) {
      return this.httpService.get<SessionDataResponse>(this.environmentService.environment.GET_USER_SESSION + "/" + orgId).pipe(map((userdata) => userdata));
    } else if (Utils.isDefined(sessionData)) {
      return of({
        userCookie: undefined,
        sessionUser: sessionData
      });
    } else {
      return this.httpService.get<SessionDataResponse>(this.environmentService.environment.GET_USER_SESSION).pipe(map((userdata) => userdata));
    }
  }

  private getOrganizationInformation(orgId?: number): Observable<Response<Organization[]>>{
    const orgData = this.localStorageService.getItem<Response<Organization[]>>(LOCAL_STORAGE.ORGANIZATION_DATA);
    // has the org id changed, or has the previously retrieved information expired?
    if ((orgId && orgId > 0) || (!orgData)) {
      return this.httpService.get(this.environmentService.environment.ORGANIZATION_API_ENDPOINT + "v7/organization").pipe(map((response) => new Response<Organization[]>(response)));
    }
    // if not, use the locally stored data
    else {
      return of(orgData);
    }
  }

  public checkForUnauthorizedOrUnknownError(response: HttpErrorResponse): boolean {
    //401 is from Authorization Microservice
    //0 is from Public Services
    //Both caused by usage of invalid token
    if ((response.status == 401 
      && response.error.message == "Unauthorized") 
    || (response.status == 0 
      && response.statusText == "Unknown Error" 
      && response.url == (this.environmentService.environment.ORGANIZATION_API_ENDPOINT + "v7/organization"))) {
        return true;
    }
  }

  public deleteCookies() {
    let domain = getDomain(window.location.origin);

    if(domain === null){
      domain = 'bccapp.com';
    }

    //TODO: There are now two cookies, 'Token' and 'bc-token' that have the same value.
    //Will want to use one eventually?
    this.cookieService.delete('Token', '/', domain, true, "Lax");
    this.cookieService.delete(COOKIES.AUTH_TOKEN, '/', domain, true, "Lax");
    this.cookieService.delete(COOKIES.USER_TOKEN, '/', domain, true, "Lax");
    this.cookieService.delete(COOKIES.NEXT_URL, '/', domain, true, "Lax");
    this.cookieService.delete(COOKIES.ORG, '/', domain, true, "Lax");
  }

  public deleteLocalStorage() {
    //TODO: There are now two tokens in Local Storage, Token' and 'bc-token', that have the same value.
    //Will want to only use one eventually?
    this.localStorageService.deleteItem('Token');
    this.localStorageService.deleteItem(LOCAL_STORAGE.AUTH_TOKEN);
    this.localStorageService.deleteItem(LOCAL_STORAGE.ORGANIZATION_DATA);
    this.localStorageService.deleteItem(LOCAL_STORAGE.SESSION_DATA);
  }

  public getSessionInformation(orgId?: number): Observable<SessionUser> {
    let newSessionInformation = new SessionUser();
    return forkJoin([
      this.getUserInformation(orgId),
      this.getOrganizationInformation(orgId),
    ]).pipe(
      map(([sessionResponse, orgResponseData]) => {
        if (sessionResponse.userCookie) {
          this.cookieService.set(COOKIES.USER, sessionResponse.userCookie, cookieOpts);
        }

        this.localStorageService.setItem(LOCAL_STORAGE.ORGANIZATION_DATA, orgResponseData, new Date(Date.now() + twelveHoursInMs));

        if (sessionResponse.sessionUser) {
          this.localStorageService.setItem(LOCAL_STORAGE.SESSION_DATA, sessionResponse.sessionUser, new Date(Date.now() + twelveHoursInMs)); 
          newSessionInformation = SessionUser.fromJSON(sessionResponse.sessionUser);
          newSessionInformation.mapOrg(orgResponseData);

          // update the orgId stored in cookies
          this.cookieService.set(COOKIES.ORG, newSessionInformation.CurrentOrganization.Id.toString(), cookieOpts);
        }
        this.sessionState.setSessionState(newSessionInformation);
        return newSessionInformation;
      }),
      catchError((error) => {
        //Checking status code 0 and 401 because forkjoin contains two API calls
        //which can create a race condition in which error could appear first.
        if (this.checkForUnauthorizedOrUnknownError(error)) {
          this.deleteCookies();
          this.deleteLocalStorage();
          //TODO: 11-17-23 Find a less abrupt way of showing correct nav bar instead of reloading page/navigating away (Pre-Login Component)
          window.location.assign(window.location.href);
        }
        return of(newSessionInformation);
      })
    ).pipe(share());
  }

  public getSystemNotifications(): Observable<SystemNotification[]> {
      return this.httpService.get<SystemNotification[]>(this.environmentService.environment.GET_SYSTEM_NOTIFICATIONS).pipe(
        catchError((error: any) => {
          //if error when fetching notifications, return empty list
          return [];
        }),
      );
  }
}
