import { LOCAL_STORAGE, COOKIES } from './utilities/constants';
import { CookieService } from 'ngx-cookie-service';
import { NavigationService } from './service/navigation/navigation.service';
import { Component, Inject, Input, OnInit } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Observable, of, Subject, Subscription } from "rxjs";
import { Environment } from "../environments/environment";
import Utils from "./utilities/utils";
import { UserService } from "./service/user/user.service";
import { SessionUser } from "./models/user/user";
import packageJson from "../../package.json";
import { LocalStorageService } from './service/localstorage/localstorage.service';
import { UrlService } from './service/url/url.service';
import { getSubdomain } from 'tldts';
import { Title } from '@angular/platform-browser';


@Component({
  selector: "shared-layout",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @Input() public orgid: number;
  public isIframe = false;
  public userLoggedIn$: Observable<boolean> = of(false);
  public bcc_connect_logo: string = Environment.BCC_CONNECT_LOGO;
  public bc_logo: string = Environment.BC_LOGO;
  public navOverlayColor = "";
  public sessionInformation: SessionUser;
  public spinnerState: boolean;
  public connectHomePageRoute: string;
  public hideOrgHeader = false;

  private readonly _destroying$ = new Subject<void>();
  private _subscription: Subscription[];


  constructor(
    private userService: UserService,
    private navigationService: NavigationService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private urlService: UrlService,
    @Inject(DOCUMENT) private document: Document,
    private pageTitle: Title
  ) {
    this._subscription = [];
    this.sessionInformation = new SessionUser();
    this.spinnerState = false;
  }

  public ngOnInit(): void {
    this.setFavicon();
    this.setPageTitle();

    console.debug("You are using shared-layout version " + packageJson.version);
    // returns null when running as localhost
    const subDomains = getSubdomain(window.location.origin) ?? "";

    this.connectHomePageRoute = this.urlService.getBcConnectHomePageRoute(subDomains)
    this.isIframe = window !== window.parent && !window.opener;

    let token = this.cookieService.get(COOKIES.AUTH_TOKEN);
    if (token) {
      /**  when should token actually expire in local storage? this is reran on every page load, so probably
       need some more deliberate solution design here. For now, if we have an unexpired token in local storage,
       do not replace with a new entry. Use the existing until it expires.
       note: we only really enter a weird state on the home page of bc connect where the user is not required
       to be authenticated, but if a token is present & but expired, their nav displays funny
      */
      const expirationDate = new Date(Date.now() + 1000 * 60 * 60 * 2);
      this.localStorageService.setItem(LOCAL_STORAGE.AUTH_TOKEN, this.cookieService.get(COOKIES.AUTH_TOKEN), expirationDate);

      this.userLoggedIn$ = of(true);
      const orgCookie = this.cookieService.get(COOKIES.ORG);

      this._subscription.push(
        // First, retrieve any locally stored session information
        this.userService.getSessionInformation().subscribe((sessionUser) => {
          this.sessionInformation = sessionUser;
          // Then, check if either the orgId has been updated since last stored in the cookie or if the stored current orgid no longer matches the selected orgid
          if (this.shouldOrganizationDataBeLoaded(orgCookie)) {
            this.sessionInformation = null;
            this._subscription.push(
              this.userService.getSessionInformation(this.orgid).subscribe((sessionUser) => {
                this.sessionInformation = sessionUser;
              })
            );
          }
        }
      ));
    }

    this._subscription.push(
      this.navigationService.getHiddenOrganizationHeaderURLs()
        .subscribe(urlArray => {
          for (const url of urlArray) {
            if (location.href.includes(url)) {
              this.hideOrgHeader = true;
              break;
            }
          }
        })
    );
  }

  public recieveMessage($event: string): void {
    this.navOverlayColor = $event;
  }

  public shouldOrganizationDataBeLoaded(orgId: string): boolean {
    return (Utils.isStringWithLength(orgId) && this.orgid.toString() !== orgId) || this.sessionInformation.CurrentOrganization?.Id.toString() !== this.orgid.toString();
  }

  private setPageTitle(): void {
    if (this.isTitleUnset()) {
      this.pageTitle.setTitle("BC Connect");
    }
  }

  private isTitleUnset(): boolean {
    return !this.pageTitle.getTitle();
  }

  private setFavicon(): void {
    let node = document.createElement('link');
    node.href = "https://prod-master-s3-15ydb0d922f6m-contentbucket-eo8pl0s7myd9.s3.amazonaws.com/logo/BCCIcon.ico";
    node.type = "image/x-icon";
    node.rel = "icon";
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  public ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    if (Utils.isArrayWithLength(this._subscription)) {
      this._subscription.forEach((s) => s.unsubscribe());
    }
  }
}
