import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BYPASS_AUTH } from "./secure-interceptor";

@Injectable({ providedIn: "root" })
export class JsonService {
  /**
   * Function: get()
   * @funciton
   */
  public get(file: string): Observable<any> {
    return this.httpClient.get(file, {context: new HttpContext().set(BYPASS_AUTH, true)});
  }
  /**
   * Function: constructor()
   */
  constructor(private httpClient: HttpClient) {}
}
