import { Error } from "./error";
import Utils from "../../utilities/utils";

export class Response<T> {
  public data: any;
  public errors: Error[] | undefined;
  public total: number;

  public hasErrorsInArray(response: Response<T>): boolean {
    return Utils.isArrayWithLength(response.errors);
  }

  public hasData(): boolean {
    return Utils.isArrayWithLength(this.data) || (!Utils.isArray(this.data) && Utils.isObject(this.data));
  }

  constructor(options?: Partial<Response<T>>) {
    this.data = [];
    this.total = 0;
    this.errors = [];

    if (Utils.isObject(options) || Utils.isArray(options)) {
      if (Utils.isArrayWithLength(options)) {
        this.data = [options];
      } else if (!Utils.isArray(options)) {
        this.data.push(options);
      }

      if (Utils.hasOwnProperty(options, "errors")) {
        this.errors = options?.errors?.map((e) => {
          return new Error(e);
        });
      }
    }
  }
}
