import { Observable, of } from "rxjs";
import { IQueryParams } from "../../interfaces/IQueryParams";
import { NavigationOrganizationRestriction } from "./navigation-organization-restriction";

export class Dropdown {
  public name: string;
  public className: string;
  public href: Observable<string>;
  public brand: number;
  public queryParameters: IQueryParams[];
  public featureFlag: boolean;
  public featurePermission: number;
  public navigationOrganizationRestriction: NavigationOrganizationRestriction;

  constructor() {
    this.name = "";
    this.className = "";
    this.href = of("");
    this.brand = 1;
    this.queryParameters = [
      {
        displayName: "",
        value: 0,
      },
    ];
    this.featureFlag = true;
    this.featurePermission = 0;
    this.navigationOrganizationRestriction = 0;
  }
}
