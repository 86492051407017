/*
* This enum is used to enforce certain restrictions on organization
* display in the menu. These enum values are directly
* defined per item in the navigation.json file.
*/
export enum NavigationOrganizationRestriction {
    None = 0,
    Dealer,
    Distributor,
    Parent
}