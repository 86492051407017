import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { QuestionAccordion } from "./question-accordion.component";

@NgModule({
  imports: [CommonModule],
  providers: [],
  declarations: [QuestionAccordion],
  exports: [QuestionAccordion],
})
export class QuestionAccordionModule {}
