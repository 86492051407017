import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PostLoginComponent } from "./post-login.component";

const LoginRoutes: Routes = [
  //Put paths here
  {
    path: "projectdashboard",
    component: PostLoginComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forChild(LoginRoutes)],
  providers: [],
})
export class PostLoginRoutesModule {}
