import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpContextToken } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { COOKIES } from "../../utilities/constants";

@Injectable()
export class SecureInterceptor implements HttpInterceptor
{
    constructor(private cookieService: CookieService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        //TODO: reduce to usie AUTH_TOKEN after full cookie cut over
        let token = this.cookieService.get(COOKIES.AUTH_TOKEN);
        if(!token) {
            token = this.cookieService.get('Token');
        }
        const request = req.context.get(BYPASS_AUTH) ? req.clone() : req.clone({headers: req.headers.append('Authorization', `Bearer ${token} `)});
        return next.handle(request);
    }
}

export const BYPASS_AUTH = new HttpContextToken(() => false);
