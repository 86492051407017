import { Dropdown } from "./dropdown";
import { IQueryParams } from "../../interfaces/IQueryParams";
import { Observable, of } from "rxjs";

export class NavigationMenuItems {
  public name: string;
  public className: string;
  public href: Observable<string>;
  public brand: number;
  public selected: boolean;
  public dropdown: Dropdown[];
  public queryParameters: IQueryParams[];
  public featureFlag: boolean;
  public featurePermission: number; 

  constructor() {
    this.name = "";
    this.className = "";
    this.href = of("");
    this.brand = 1;
    this.selected = false;
    this.dropdown = [];
    this.queryParameters = [
      {
        displayName: "",
        value: 0,
      },
    ];
    this.featureFlag = true;
    this.featurePermission = 0;
  }
}
