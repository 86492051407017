import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PostLoginComponent } from "./post-login.component";
import { PostLoginRoutesModule } from "./post-login.routes";
import { HttpClientModule } from "@angular/common/http";
import { JsonService } from "../../service/http/json.service";
import { MatExpansionModule } from "@angular/material/expansion";
import { PermissionDirective } from "../../directives/permissions/permissions.directive";
import { AuthService } from "../../service/authorization/authorization.service";
import { QuestionAccordionModule } from "../question-accordion/question-accordion.module";

@NgModule({
  imports: [CommonModule, PostLoginRoutesModule, HttpClientModule, MatExpansionModule, QuestionAccordionModule],
  providers: [JsonService, AuthService],
  declarations: [PostLoginComponent, PermissionDirective],
  exports: [PostLoginComponent, PermissionDirective],
})
export class PostLoginModule {}
