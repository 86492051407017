import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Injector, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"; // Import
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

import { AppComponent } from "./app.component";

import { createCustomElement } from "@angular/elements";
import { PreLoginModule } from "./components/pre-login/pre-login.module";
import { PostLoginModule } from "./components/post-login/post-login.module";
import { FooterModule } from "./components/footer-bar/footer.module";
import { NotificationBannerModule } from "./components/notification-banner/notification-banner.module";
import { APP_BASE_HREF } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { SpinnerModule } from "./components/spinner/spinner.module";
import { SecureInterceptor } from "./service/http/secure-interceptor";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    PreLoginModule,
    PostLoginModule,
    FooterModule,
    SpinnerModule,
    NotificationBannerModule,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: "/",
    },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true }
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    const customAppComponent = createCustomElement(AppComponent, { injector });
    customElements.define("shared-layout", customAppComponent);
  }
  ngDoBootstrap() {}
}
