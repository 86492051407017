import { getDomain, getSubdomain } from 'tldts';
import { SessionStateService } from "./../../service/user/session-state.service";
import { CookieService } from "ngx-cookie-service";
import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Navigation } from "../../models/navigation/navigation";
import { JsonService } from "../../service/http/json.service";
import { Observable, Subscription, switchMap, of, map } from "rxjs";
import Utils from "../../utilities/utils";
import { NavigationTile } from "../../models/navigation/navigation-tile";
import { NavigationMenuItems } from "../../models/navigation/navigation-menu-items";
import { UrlService } from "../../service/url/url.service";
import { SessionUser } from "../../models/user/user";
import { NavigationService } from "../../service/navigation/navigation.service";
import { AUTHENTICATION, COOKIES, LOCAL_STORAGE, MONOLITH_WEB } from '../../utilities/constants';
import { EnvironmentService } from '../../service/environment/environment.service';
import { LocalStorageService } from '../../service/localstorage/localstorage.service';

@Component({
  selector: "post-login",
  templateUrl: "./post-login.component.html",
  styleUrls: ["./post-login.component.scss"],

})
export class PostLoginComponent implements OnInit, OnDestroy {
  @Output() public tileColorEmitter: EventEmitter<string> = new EventEmitter();
  @Input() public navOverlayColor = "black"; // decorate the property with @Input()

  private _subcriptions: Subscription[];

  public bcc_icon: string = this.environmentService.environment.BCC_ICON;
  public mappedNavigation$: Observable<Navigation>;
  public selectedNavTile: NavigationTile;
  public selectedMenu: NavigationMenuItems;
  public nonSelectedMenu: NavigationMenuItems[];
  public sessionInformationObject: SessionUser;
  public panelOpenState = false;
  public jsonFile: Observable<any>;
  public sessionUser$: Observable<SessionUser>;
  public menuIsOpen = false;
  public submenuOpenStates: object = {};
  public locationChangeRoute: string = "";
  public isMobile: boolean = false;

  constructor(
    private jsonService: JsonService,
    private urlService: UrlService,
    private navigationService: NavigationService,
    private sessionState: SessionStateService,
    private cookieService: CookieService,
    private environmentService: EnvironmentService,
    private changeDetectorRef: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private breakpointObserver: BreakpointObserver
  ) {
    this._subcriptions = [];
    this.selectedMenu = new NavigationMenuItems();
    this.nonSelectedMenu = [];
    this.mappedNavigation$ = new Observable();
    this.sessionInformationObject = new SessionUser();
    this.selectedNavTile = new NavigationTile();
    this.breakpointObserver.observe(["(max-width: 992px)"]).subscribe((result: BreakpointState) => {
      this.isMobile = result.matches;
    });
  }

  public ngOnInit(): void {
    this.setLocationChangeRoute();

    this._subcriptions.push(
      this.getNavigationJsonFile()
        .pipe(
          switchMap((jsonFile) => {
            this.jsonFile = of(jsonFile);
            return this.sessionState.getSessionState();
          }),
          map((sessionState) => {
            this.sessionInformationObject = sessionState;
            this.sessionUser$ = of(sessionState);
            this.mapNavigation(this.jsonFile);
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe()
    );
  }

  private setLocationChangeRoute(): void {
    const subdomain = getSubdomain(window.location.origin) ?? "";
    this.locationChangeRoute = `${this.urlService.getBcConnectHomePageRoute(subdomain)}${MONOLITH_WEB.LOCATION_CHANGE_ROUTE}`;
  }

  public ngOnDestroy(): void {
    if (Utils.isArrayWithLength(this._subcriptions)) {
      this._subcriptions.forEach((s) => s.unsubscribe);
    }
  }

  /**
   * Initiates log out flow to clear user's cookies and localstorage 
   * and redirect them to the authentication app
   */
  public logout(): void {
    this.cleanupLocalStorage([LOCAL_STORAGE.ORGANIZATION_DATA, LOCAL_STORAGE.USER_REDIRECTED, LOCAL_STORAGE.AUTH_TOKEN, LOCAL_STORAGE.USER_TOKEN, LOCAL_STORAGE.SESSION_DATA]);
    this.cleanupCookies("/", getDomain(window.location.origin));

    let domain = getDomain(window.location.origin);
    // KB: short term fix for developer environments
    // get domain returns null when origin is localhost, so force to use bccapp.com in that case
    if (domain === null) {
      domain = 'bccapp.com';
    }

    const redirectUrl = this.buildLogoutRedirectUrl();
    this.cookieService.set(COOKIES.NEXT_URL, redirectUrl, null, '/', domain, true, COOKIES.LAX_POLICY);
    window.location.assign(this.urlService.getAuthenticationApplicationUrl() + AUTHENTICATION.LOGOUT_ROUTE);
  }
  public togglePanelOpenState(flag: boolean): void {
    this.panelOpenState = flag;
  }

  public toggleMenu(): void {
    this.menuIsOpen = !this.menuIsOpen;
    if (!this.menuIsOpen) {
      //if menu is being closed, close all submenus
      for (const key in this.submenuOpenStates) {
        this.submenuOpenStates[key] = false;
      }
    }
  }

  public toggleSubmenuDropdown(menu: any): void {
    //Toggle between true and false. If this.submenuOpenStates[key] is undefined, 
    //the submenu dropdown is being opened for the first time. This expression will set it 
    //to true and will go on to toggle between true and false in future calls with that key.
    this.submenuOpenStates[menu?.name] = !this.submenuOpenStates[menu?.name];
  }

  private buildLogoutRedirectUrl(): string {
    const subDomains = getSubdomain(window.location.origin);
    return this.urlService.getBcConnectHomePageRoute(subDomains);
  }

  private mapNavigation(jsonFile: Observable<any>): void {
    this.mappedNavigation$ = jsonFile.pipe(
      switchMap((navData) => {
        this.navigationService.setHiddenOrganizationHeaderURLs(navData.hideOrganizationURLs);
        return this.navigationService.mapNavigationTile(navData.navigation.tiles);
      }),
      map((mappedNavigation) => {
        this.concatenateEnvironmentUrls(mappedNavigation);
        this.buildTopNavigation(mappedNavigation.navigationTiles);
        if (mappedNavigation?.navigationTiles?.length > 0) {
          if (!mappedNavigation?.navigationTiles.some((x) => x.selected)) {
            this.clickNavTile(mappedNavigation.navigationTiles[0]);
          }
        }
        return mappedNavigation;
      })
    );
  }

  private clickNavTile(newlySelectNavTile: NavigationTile): void {
    this.selectedNavTile.selected = false;
    newlySelectNavTile.selected = true;
    this.selectedNavTile = newlySelectNavTile;
    this.tileColorEmitter.emit(this.selectedNavTile.backgroundColor);
    this.mappedNavigation$.pipe(map((mappedNavigation) => this.buildTopNavigation(mappedNavigation.navigationTiles)));
  }

  public getNavigationJsonFile(): Observable<any> {
    return this.jsonService.get(this.environmentService.environment.NAVIGATION_OBJECT_URL);
  }

  private cleanupLocalStorage(keys: string[]): void {
    for (const key in keys) {
      this.localStorageService.deleteItem(keys[key]);
    }
  }

  private cleanupCookies(path: string, domain: string): void {
    this.cookieService.deleteAll(path, domain);
  }

  private buildTopNavigation(navigationTiles: NavigationTile[]): void {
    navigationTiles.forEach((nav) => {
      if (nav.selected === true) {
        console.log('selected: ' + nav.name);
        this.selectedNavTile = nav;
        this.tileColorEmitter.emit(this.selectedNavTile.backgroundColor);
        return;
      }
    });
    this.selectedNavTile?.navigationMenuItems?.forEach((menu) => {
      if (menu.selected === true) {
        this.selectedMenu = menu;
        return;
      }
    });
    this.nonSelectedMenu = this.selectedNavTile?.navigationMenuItems?.filter((menu) => menu.selected !== true);
  }

  private concatenateEnvironmentUrls(mappedNavigation: Navigation): void {
    mappedNavigation.navigationTiles.forEach((tiles: NavigationTile) => {
      tiles.imageSrc = this.bcc_icon + tiles.imageSrc;
    });
  }
}
