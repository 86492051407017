export enum OrgPermissionId {
  TJProducts = 1,
  BcConnectNotifications = 2,
  ProjectValueAutoCalculation = 4,
  ProjectStatusTracking = 5,
  ProjectPreferencesTab = 6,
  ProjectAttachmentsUploadFiles = 7,
  ProjectAttachmentEmailFiles = 8,
  BulkMaterialListPriceExport = 9,
  BcFramerAutoUploadDesignFile = 10,
  InventoryPrices = 11,
  InventorySkuMappingCustomHangerAndUdm = 12,
  ReportProjectValue = 13,
  OptimizationWithInfiniteQuantities = 14,
  ImportSawFile = 15,
  ImportCompletedFramerML = 16,
  LPProducts = 17,
}

export const UserPermissionId: Map<string, number> = new Map([
  ["Unused", 0],
  ["ReadBuilder", 1],
  ["CreateBuilder", 2],
  ["UpdateBuilder", 3],
  ["ReadProject", 4],
  ["CreateProject", 5],
  ["UpdateProject", 6],
  ["ReadContact", 7],
  ["CreateContact", 8],
  ["UpdateContact", 9],
  ["ReadJoistSelector", 10],
  ["ReadBeamSelector", 11],
  ["ReadEquivalentJoists", 12],
  ["ReadHeaderSelector", 13],
  ["ReadLibrary", 14],
  ["ReadDesignDashboard", 15],
  ["ReadDesignTicket", 16],
  ["CreateDesignTicket", 17],
  ["EditDesignTicketTop", 18],
  ["EditDesignTicketBottom", 19],
  ["BCCalcNew", 20],
  ["BCCalcOpen", 21],
  ["ReadMaterialList", 22],
  ["CreateMaterialList", 23],
  ["UpdateMaterialList", 24],
  ["ReadInventory", 25],
  ["UpdateInventory", 26],
  ["ReadStockSettings", 27],
  ["UpdateStockSettings", 28],
  ["ReadMillSelection", 29],
  ["UpdateMillSelection", 30],
  ["ReadDistributorSelection", 31],
  ["UpdateDistributorSelection", 32],
  ["ReadStockLengths", 33],
  ["UpdateStockLengths", 34],
  ["ReadOperationsDashboard", 35],
  ["ReadOperationsTicket", 36],
  ["CreateOperationsTicket", 37],
  ["UpdateOperationsTicketTop", 38],
  ["UpdateOperationsTicketBottom", 39],
  ["ReadOrganization", 40],
  ["UpdateOrganization", 41],
  ["CreateOrganization", 42],
  ["UpdateUser", 43],
  ["CreateUser", 44],
  ["ReadUser", 45],
  ["ReadAnalytics", 46],
  ["UpdateAnalytics", 47],
  ["SupportAccess", 48],
  ["HelpAccess", 49],
  ["NotificationAccess", 50],
  ["ProfileAccess", 51],
  ["LocationSettingsAccess", 52],
  ["ReadJobPackList", 53],
  ["UpdateJobPackList", 54],
  ["ReadSKUSettings", 55],
  ["UpdateSKUSettings", 56],
  ["ReadCustomDealer", 57],
  ["ManageCustomDealer", 58],
  ["EditOperationsTicketStatus", 59],
  ["ViewSawSettings", 60],
  ["EditSawSettings", 61],
  ["AssignSawTekAdmin", 62],
  ["ReadInventoryQuantities", 63],
  ["ManageInventoryQuantities", 64],
  ["EditBuilderDealer", 65],
  ["ReadHangerSettings", 66],
  ["UpdateHangerSettings", 67],
  ["ReadInventoryPrices", 68],
  ["UpdateInventoryPrices", 69],
  ["ReadPriceReport", 70],
  ["UpdatePriceReport", 71],
  ["ViewRemoteDesigners", 72],
  ["ManageRemoteDesigners", 73],
  ["ReadUDMSettings", 74],
  ["UpdateUDMSettings", 75],
  ["ReadDashboardAdmin", 76],
  ["UpdateDashboardAdmin", 77],
  ["ReadWebFOCUSReport", 78],
  ["AccessInventoryApp", 79],
  ["ReadPriceManagement", 80],
  ["ReadOptimizer", 81],
  ["CreateSpecifierAttachment", 82],
  ["CreateProjectAttachment", 83],
  ["UpdateProjectAttachment", 84],
  ["ConfirmProjectAttachment", 85],
  ["CreateREMAN", 86],
  ["DeleteREMAN", 87],
  ["ViewSharedAttachment", 88],
  ["CreateSharedAttachment", 89],
  ["UpdateSharedAttachment", 90],
  ["ConfirmSharedAttachment", 91],
  ["DeleteSharedAttachment", 92],
  ["UpdateLocationsPermissions", 93],
  ["ViewLocationsPermissions", 94],
  ["ManageAttachmentSubfolders", 95],
  ["UpdateBCConnectSettings", 96],
  ["ReadBCConnectSettings", 97],
  ["ReadProjectSharingRelationships", 98],
  ["UpdateProjectSharingRelationships", 99],
  ["AddProjectCollaborators", 100],
  ["ApiOnlyAccess", 101],
  ["ViewQuoteSheet", 102],
  ["EditQuoteSheet", 103],
  ["GenerateQuote", 104],
  ["ReadInventoryCosts", 105],
  ["UpdateInventoryCosts", 106],
  ["ManageStagingAndBundling", 107],
  ["ViewReports", 108],
  ["PreserveProject", 109],
  ["DeleteProjectAttachment", 110],
  ["ViewMLFulfillment", 111],
  ["UpdateOperationsSettings", 112],
  ["ViewBatchScheduling", 113],
  ["UpdateBatchScheduling", 114],
  ["UpdateProjectMilestoneDate", 115],
  ["EditAssignedDesigner", 116],
  ["ViewDesignQueue", 117],
  ["EditDesignWorkStatus", 118],
  ["EditEstimatedDesignTime", 119],
  ["ViewTractBuilders", 120],
  ["CreateDesignModels", 121],
  ["EditDesignModels", 122],
  ["CreateTractProject", 123],
  ["DeleteDesignModels", 124],
  ["ManageDesignModels", 125],
  ["Hidden", -1]
]);
