import { Component, OnInit } from "@angular/core";
import { FeatureFlagService } from "../../service/featureflag/featureflag.service";

@Component({
  selector: "question-accordion",
  templateUrl: "./question-accordion.component.html",
  styleUrls: ["./question-accordion.component.scss"],
})
export class QuestionAccordion implements OnInit {
    private displayNewLandingPage: boolean;
    public helpMenuIsOpen = false;
    
    constructor(
        private featureFlagService: FeatureFlagService
    ) {
        this.featureFlagService = featureFlagService;
        this.featureFlagService.initFlag('show-new-landing-page', false);
    }
    
    public ngOnInit(): void {
        this.featureFlagService.flagChange$.subscribe((result) => {
            this.displayNewLandingPage = result as boolean;
        });
    }

    public toggleHelpDropdown(): void {
        this.helpMenuIsOpen = !this.helpMenuIsOpen;
      }
}
