import { Response } from "../../models/http/response";
import { Organization, CurrentOrganization } from "../../interfaces/IUser";

export class SessionUser {
  public BCUserId = 0;
  public NotificationCount = 0;
  public UserName = "";
  public DisplayUserName = "";
  public IsBCCAdminRole = false;
  public Permissions: number[] = [];
  public StartingLocationId = 0;
  public CurrentOrganization: CurrentOrganization = null!;
  public ListAllOrgs: Organization[] = null!;

  static fromJSON(json: any): SessionUser {
    const su = new SessionUser();
    su.BCUserId = json["BCUserId"];
    su.NotificationCount = json["NotificationCount"];
    su.UserName = json["UserName"];
    su.DisplayUserName = json["DisplayUserName"];
    su.IsBCCAdminRole = json["IsBCCAdminRole"];
    su.StartingLocationId = json["StartingLocationId"];
    su.CurrentOrganization = json["CurrentOrganization"];
    su.Permissions = json["Permissions"][su.CurrentOrganization?.Id];
    su.ListAllOrgs = json["ListAllOrgs"];
    return su;
  }

  public mapOrg(orgResponse: Response<Organization[]>) {
    this.ListAllOrgs = orgResponse.data[0].ReturnObject;
  }

  public hasPermission(permissionId: number) : boolean
  {
    return Object.values(this.Permissions)?.includes(permissionId) || permissionId === 0;
  }

  public hasOrganizationPermission(orgPermissionId: number, userPermissionId: number): boolean
  {
    return (this.CurrentOrganization.FeaturePermissions.includes(orgPermissionId) || orgPermissionId === 0) && userPermissionId === 0;
  }
}
