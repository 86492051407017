import { Injectable } from "@angular/core";
import { IQueryParams } from "../../interfaces/IQueryParams";
import { REDIRECT_HOST_MAP, Hostname, TargetEnvHostnames, QueryConstants } from "../../models/url/hostname";
import { URLMAP } from "../../models/url/url-map";
import { SessionUser } from "../../models/user/user";
import Utils from "../../utilities/utils";
import { EnvironmentService } from "../environment/environment.service";
import { UrlResources } from "../../models/url/url-resources";

const referrer = window.document.referrer;
const url = window.location.origin;
const PR_ENV_REGEX = /pr[0-9]+/;
@Injectable({ providedIn: "root" })
export class UrlService {

  public constructor(private environmentService: EnvironmentService) { }

  public getRedirectBrand(): number {
    const brand = URLMAP?.get(location.origin);
    if (brand === undefined) {
      return 0;
    }
    return brand;
  }

  //Handle pr environments
  //Find and Replace URL when parameters arent present
  public buildQueryParameters(params: IQueryParams[], sessionInfo: SessionUser): string {
    let result = "";
    params?.forEach((param) => {
      let value = param?.value;
      if (param?.displayName === QueryConstants.ORGANIZATION_ID) {
        value = sessionInfo.CurrentOrganization?.Id;
      }
      result += "?" + param?.displayName + "=" + value;
    });
    return result;
  }

  public buildUrlResource(url: string, sessionInfo: SessionUser, resources: UrlResources): string {
    let result = url;
    if (url.includes(QueryConstants.ORGANIZATION_ID_BRACKETS)) {
      result = result.replace(/\{{organizationId}}/gi, sessionInfo.CurrentOrganization?.Id.toString());
    }
    if (url.includes(QueryConstants.USER_ID_BRACKETS)) {
      result = result.replace(/\{{userId}}/gi, sessionInfo.BCUserId.toString());
    }
    if (url.includes(QueryConstants.ATTACHMENT_ID_BRACKETS)) {
      result = result.replace(/\{{attachmentId}}/gi, resources.attachmentId);
    }
    if (url.includes(QueryConstants.PROJECT_ID_BRACKETS)) {
      result = result.replace(/\{{projectId}}/gi, resources.projectId);
    }
    if (url.includes(QueryConstants.BUILDER_ID_BRACKETS)) {
      result = result.replace(/\{{builderId}}/gi, resources.builderId);
    }
    if (url.includes(QueryConstants.DEALER_ORG_ID_BRACKETS)) {
      result = result.replace(/\{{dealerOrganizationId}}/gi, resources.dealerOrgId);
    }
    if (url.includes(QueryConstants.CONTACT_ID_BRACKETS)) {
      result = result.replace(/\{{contactId}}/gi, resources.contactId);
    }
    if (url.includes(QueryConstants.REPORT_ID_BRACKETS)) {
      result = result.replace(/\{{reportId}}/gi, resources.reportId);
    }
    return result;
  }

  public checkIfLocalhost(brand: number): string {
    if (location.hostname.includes(TargetEnvHostnames.LOCAL_HOST)) {
      return location.protocol + "//" + location.host;
    }
    if (this.checkIfBCConnectAngularApp(url) && this.checkIfBCConnectWebApp(referrer) && brand === Hostname.bcconnect) {
      return referrer.endsWith("/") ? referrer.slice(0, -1) : referrer;
    }
    if (
      (brand === Hostname.bccapp || brand === Hostname.bcconnect) &&
      (location.hostname.includes(TargetEnvHostnames.PR) || location.hostname.includes(TargetEnvHostnames.CANARY))
    ) {
      return location.protocol + "//" + location.host;
    }
    return "";
  }

  public getBcConnectHomePageRoute(subDomains: string): string {
    switch (this.environmentService.environment.env) {
      case 'local':
        return this.buildLocalConnectHomePageRoute(subDomains);
      case 'dev':
      case 'stable':
      case 'int':
        return this.buildDevIntStableConnectHomePageRoute();
      case 'stage':
        return this.buildStageConnectHomePageRoute(subDomains);
      case 'prod':
        return this.buildProdConnectHomePageRoute(subDomains);
    }
    // If all else fails, safest to send to Prod Live
    return 'https://www.bcconnect.com';
  }

  public checkIfBCConnectAngularApp(url: string): boolean {
    return Hostname.bccapp === (URLMAP?.get(url) || Hostname.bcconnect);
  }

  public checkIfBCConnectWebApp(url: string): boolean {
    return Utils.isDefined(url) && Hostname.bcconnect === (URLMAP?.get(url) || Hostname.bcconnect);
  }

  /**
   * Gets the Authentication App URL for the specified environment
   * @returns authentication app URL 
   */
  public getAuthenticationApplicationUrl(): string {
    return this.environmentService.environment.AUTHENTICATION.AUTH_BASE_URL;
  }

  //If valid url, return it, else default to the bcconnect ap url
  private checkValidUrl(url: string | undefined): string {
    if (url && Utils.isValidHttpUrl(url)) {
      return url;
    } else {
      return this.environmentService.environment.AUTHENTICATION.REDIRECT_URL.BCCONNECTAPP;
    }
  }

  /**
* Builds the log out redirect url for PR environments
* @param subdomain 
* @returns 
*/
  private buildLocalConnectHomePageRoute(subdomains: string): string {
    let redirectUrl = 'https://';
    const splitSubDomains = subdomains.split('.');
    // Is this a PR environment?
    const prEnvIdentifier = splitSubDomains.filter(x => PR_ENV_REGEX.test(x));
    const isPrEnv = prEnvIdentifier?.length;
    if (isPrEnv) {
      redirectUrl = `${redirectUrl}${prEnvIdentifier[0]}.`;
    }

    redirectUrl = `${redirectUrl}dev.${this.environmentService.environment.REDIRECT.BCCONNECTDOMAIN}`
    return redirectUrl;
  }

  /**
   * Builds the log out redirect url for DEV STABLE, or INT environment
   * @param subdomain 
   * @returns 
   */
  private buildDevIntStableConnectHomePageRoute(): string {
    let redirectUrl = 'https://';
    redirectUrl = `${redirectUrl}${this.environmentService.environment.REDIRECT.BCCONNECTSUBDOMAIN}.${this.environmentService.environment.REDIRECT.BCCONNECTDOMAIN}`
    return redirectUrl;
  }

  /**
   * Builds the log out redirect url for STAGE environment
   * @param subdomain 
   * @returns 
   */
  private buildStageConnectHomePageRoute(subdomain: string): string {
    let redirectUrl = 'https://';
    // Is this a canary route?
    if (Utils.isStringWithLength(subdomain) && subdomain.search('canary') > -1) {
      const splitSubDomains = subdomain.split('.');
      // Are we logging out from a partner canary environment?
      const isPartner = splitSubDomains.filter(x => this.checkForPartnerSubdomain(x))?.length > 0;
      if (!isPartner) {
        redirectUrl = `${redirectUrl}canary.`;
      }
    }
    redirectUrl = `${redirectUrl}${this.environmentService.environment.env}.${this.environmentService.environment.REDIRECT.BCCONNECTDOMAIN}`
    return redirectUrl;
  }

  private checkForPartnerSubdomain(subdomain: string): boolean {
    return subdomain.search(this.environmentService.environment.REDIRECT.BCOPTISUBDOMAIN) > -1 || subdomain.search(this.environmentService.environment.REDIRECT.BCCALSUBDOMAIN) > -1;
  }

  /**
 * Builds the log out redirect url for PROD environment
 * @param subdomain 
 * @returns 
 */
  private buildProdConnectHomePageRoute(subdomain: string): string {
    let redirectUrl = 'https://';
    // is this a canary route?
    if (Utils.isStringWithLength(subdomain) && subdomain.search('canary') > -1) {
      const splitSubDomains = subdomain.split('.');
      // Are we logging out from a partner canary environment?
      const isPartner = splitSubDomains.filter(x => this.checkForPartnerSubdomain(x))?.length > 0;
      if (!isPartner) {
        redirectUrl = `${redirectUrl}canary.`;
      }
    }
    redirectUrl = `${redirectUrl}${this.environmentService.environment.REDIRECT.BCCONNECTDOMAIN}`
    return redirectUrl;
  }
}
