import Utils from "./utils";

export default class DateTimeUtils {
  static toDateFromMonthDayYear(month: number, day: number, year: number): Date {
    if (!Utils.isNumber(month) || !Utils.isNumber(day) || !Utils.isNumber(year)) {
      return null;
    }
    const monthStr = month < 10 ? `0${month}` : month.toString();
    const dayStr = day < 10 ? `0${day}` : day.toString();
    const date = new Date(`${year.toString()}-${monthStr}-${dayStr}T00:00:00`);
    return date;
  }

  static getCurrentYear(): number {
    const todaysDate = new Date();
    return todaysDate.getFullYear();
  }

  static isDateInBetweenDates(date: Date, beginningDate: Date, endDate: Date): boolean {
    if (date?.getTime() <= endDate?.getTime() && date?.getTime() >= beginningDate?.getTime()) {
      return true;
    }
    return false;
  }
}
