import { UrlService } from './../../service/url/url.service';
import { CookieService } from 'ngx-cookie-service';
import { Component, Input, OnInit } from "@angular/core";
import Utils from "./../../utilities/utils";
import { getDomain } from "tldts";
import { AUTHENTICATION, COOKIES, LOCAL_STORAGE, MONOLITH_WEB} from '../../utilities/constants';
import { LocalStorageService } from '../../service/localstorage/localstorage.service';
import { FeatureFlagService } from "../../service/featureflag/featureflag.service";

@Component({
  selector: "pre-login",
  templateUrl: "./pre-login.component.html",
  styleUrls: ["./pre-login.component.scss"],
})
export class PreLoginComponent implements OnInit {
  private displayNewLandingPage: boolean;

  constructor(
    private cookieService: CookieService,
    private urlService: UrlService,
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService
  ) {
    this.featureFlagService = featureFlagService;
    this.featureFlagService.initFlag('show-new-landing-page', false);
  } 

  public ngOnInit(): void {
    this.featureFlagService.flagChange$.subscribe((result) => {
        this.displayNewLandingPage = result as boolean;
    });
  }
  
  /**
   * Facilitates user log in by creating cookies for use by the Authentication app,
   * then redirects the user to the Authentication application for the current environment.
   */
  public login(): void {
    if(!Utils.isStringWithLength(localStorage.getItem(LOCAL_STORAGE.USER_REDIRECTED))) {
      this.localStorageService.setItem(LOCAL_STORAGE.USER_REDIRECTED, "true", new Date(Date.now() + (1000 * 60 * 5)));
    }

    let domain = getDomain(window.location.origin);
    // KB: short term fix for developer environments
    // get domain returns null when origin is localhost, so force to use bccapp.com in that case

    if(domain === null){
      domain = 'bccapp.com';
    }
    // expire redirect URL after five minutes
    const currentDate = new Date(Date.now());
    const expirationDate = currentDate.setMinutes(currentDate.getMinutes()+5);
    this.cookieService.set(COOKIES.NEXT_URL, `${window.location.href}${MONOLITH_WEB.SIGN_IN_ROUTE}`, new Date(expirationDate), '/', domain, true, COOKIES.LAX_POLICY);
    this.cookieService.set(COOKIES.AUTHENTICATION_DOMAIN, domain);
    window.location.assign(this.urlService.getAuthenticationApplicationUrl() + AUTHENTICATION.LOGIN_ROUTE);
  }
}
