import { SessionUser } from "./../../models/user/user";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SessionStateService {
  //Subject object to store sessionInformation
  private sessionInformation = new BehaviorSubject<SessionUser>(new SessionUser());

  public setSessionState(sessionUser: SessionUser): void {
    this.sessionInformation.next(sessionUser);
  }

  public getSessionState(): Observable<SessionUser> {
    return this.sessionInformation.asObservable();
  }
}
