import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FooterComponent } from "./footer.component";

@NgModule({
  imports: [CommonModule],
  providers: [],
  declarations: [FooterComponent],
  exports: [FooterComponent],
})
export class FooterModule {}
