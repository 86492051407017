import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PreLoginComponent } from "./pre-login.component";
import { PreLoginRoutesModule } from "./pre-login.routes";
import { QuestionAccordionModule } from "../question-accordion/question-accordion.module";

@NgModule({
  imports: [CommonModule, PreLoginRoutesModule, QuestionAccordionModule],
  providers: [],
  declarations: [PreLoginComponent],
  exports: [PreLoginComponent],
})
export class PreLoginModule {}
