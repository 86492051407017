import { SameSite } from "ngx-cookie-service";

/**
 * Names for all cookies stored and used by Shared Layout
 */
export const COOKIES = {
    USER: "bc-user",
    ORG: "bc-org-id",
    AUTHENTICATION_DOMAIN: "bc-authentication-domain",
    AUTH_TOKEN: 'bc-token',
    USER_TOKEN: 'bc-user-token',
    NEXT_URL: 'bc-next-url',
    LAX_POLICY: "Lax" as SameSite,
    DISMISSED_NOTIFICATION_PREFIX: 'bc-dismissed-notification'
}; 

/**
 * Names for all local storage entries used by Shared Layout
 */
export const LOCAL_STORAGE = {
    AUTH_TOKEN: 'bc-token',
    USER_TOKEN: 'bc-user-token',
    ORGANIZATION_DATA: 'bc-org-data',
    NEXT_URL: 'bc-next-url',
    SESSION_DATA: 'bc-session-data',
    USER_REDIRECTED: 'bc-user-redirected',
    SYSTEM_NOTIFICATIONS: 'bc-system-notifications'
};

export const MONOLITH_WEB = {
    SIGN_IN_ROUTE: 'SignIn',
    LOCATION_CHANGE_ROUTE: '/SignIn/updatelocation?locationId='  
};

/**
 * Authentication Application Routes
 */
export const AUTHENTICATION = {
    LOGIN_ROUTE: '/login',
    LOGOUT_ROUTE: '/logout',
    SIGN_IN_ROUTE:'SignIn'
};

export const ROUTE_PARAMETERS = {
    PROJECT: '/project/',
    ATTACHMENT: '/attachment/',
    QUOTESHEET: '/quotesheet/',
    CUSTOM_DEALER: '/customdealer/',
    CONTACT: '/contact/',
    BUILDER: '/builder/',
    MANAGE_PROJECT: '/manage/project/',
    MANAGE_CREATE_CONTACT: '/manage/createcontact/',
    OPTI_REPORTS: '/106/',
}
