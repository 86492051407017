import { API, AUTHENTICATION, AUTHORIZATION, ENV_VAR, NAVIGATION_URL, ORGANIZATION_API, REDIRECT , FAVICON_URL} from "./api";

import { commonEnv } from "./environment.common";

const env: Partial<typeof commonEnv> = {
  env: ENV_VAR.DEV,
  hostname: API.DEV,
  REDIRECT_URL_DEV: AUTHENTICATION.BCCONNECTWEB.DEV,
  GET_USER_SESSION: AUTHORIZATION.GET_USER_SESSION.DEV,
  GET_SYSTEM_NOTIFICATIONS: AUTHORIZATION.GET_SYSTEM_NOTIFICATIONS.DEV,
  ORGANIZATION_API_ENDPOINT: ORGANIZATION_API.ENDPOINT.DEV,
  FAVICON: FAVICON_URL.DEV,
  REDIRECT: {
    BCCONNECTDOMAIN: REDIRECT.BCCONNECTDOMAIN.DEV,
    BCCONNECTSUBDOMAIN: REDIRECT.BCCONNECTSUBDOMAIN.DEV,
    BCOPTISUBDOMAIN: REDIRECT.BCOPTISUBDOMAIN.DEV,
    BCCALSUBDOMAIN: REDIRECT.BCCALCSUBDOMAIN.DEV
  },
  AUTHENTICATION: {
    AUTH_BASE_URL: AUTHENTICATION.AUTH_APP_URL.DEV,
    REDIRECT_URL: {
      BCCONNECTAPP: AUTHENTICATION.BCCONNECTAPP.DEV,
      BCCONNECTWEB: AUTHENTICATION.BCCONNECTWEB.DEV,
      BCCALC: AUTHENTICATION.BCCALC.DEV,
      BCOPT: AUTHENTICATION.BCOPT.DEV,
    },
  },
  prod: false,
  NAVIGATION_OBJECT_URL: NAVIGATION_URL.DEV,
  BCC_CONNECT_LOGO:
    "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/logo/bcconnect_logo_2023.png",
  BC_LOGO:
    "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/logo/BC_logo_362_EWP_outline_r.png",
  BCC_ICON: "https://dev-master-s3-dwtc75vfa38t-contentbucket-104v1wl3e23p4.s3.amazonaws.com/apps/",
  LAUNCH_DARKLY_CLIENT_ID: "64346d1bb0477213a34c1e5a"
};
export const Environment = Object.assign(commonEnv, env);
