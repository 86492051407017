import { Injectable } from "@angular/core";
import { LocalStorageItem } from "../../models/localstorageItem/localstorageItem";
import Utils from "../../utilities/utils";

@Injectable({ providedIn: "root" })
export class LocalStorageService {

    public setItem<T>(key: string, content: T, expiration: Date): void {
        const storageItem = new LocalStorageItem<T>();
        storageItem.data = content;
        storageItem.expiration = expiration;

        const stringifiedStorageItem = JSON.stringify(storageItem);

        localStorage.setItem(key, stringifiedStorageItem);            
    }

    public getItem<T>(key: string): T {
        const storageItemAsString = localStorage.getItem(key);
        if(!Utils.isStringWithLength(storageItemAsString)){
            return null;
        }
        const storageItem = JSON.parse(storageItemAsString) as LocalStorageItem<T>;
        storageItem.expiration = new Date(storageItem.expiration);
        if(storageItem.expiration.valueOf() < Date.now()){
            localStorage.removeItem(key);
            return null;
        }
        return storageItem?.data;
    } 

    public deleteItem(key: string): void {
        localStorage.removeItem(key);
    }

    public clearAllItems(): void {
        localStorage.clear();
    }
}
