import { Component } from "@angular/core";
import DateTimeUtils from "../../utilities/date-time-utils";

@Component({
  selector: "footer-bar",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  public getFooterYear() {
    return DateTimeUtils.getCurrentYear();
  }
}
