import { ActivatedRoute } from "@angular/router";

export default class Utils {
  static getRouteParameters(route: ActivatedRoute, param: string): string | null {
    let val = null;
    if (!Utils.isNullOrUndefined(route)) {
      val = route.snapshot.paramMap.get(param);
    }
    return val;
  }

  static isNull(o: any): boolean {
    return o === null;
  }

  static isUndefined(o: any): boolean {
    return o === undefined;
  }

  static isNullOrUndefined(o: any): boolean {
    return Utils.isNull(o) || Utils.isUndefined(o);
  }

  static isDefined(o: any): boolean {
    return !Utils.isNullOrUndefined(o);
  }

  static isObject(o: any): boolean {
    if (Utils.isDefined(o)) {
      return !Utils.isArray(o) && typeof o === "object";
    }
    return false;
  }

  static isFunction(f: any): boolean {
    return Utils.isDefined(f) && typeof f === "function";
  }

  static isNumber(o: any): boolean {
    return !isNaN(parseFloat(o)) && isFinite(o);
  }

  static isDecimal(o: any): boolean {
    return Utils.isNumber(o) && o % 1 !== 0;
  }

  static isString(o: any): boolean {
    return Utils.isDefined(o) && typeof o === "string";
  }

  static isDate(o: any) {
    if (Utils.isDefined(o)) {
      return !isNaN(new Date(o).valueOf());
    }
    return false;
  }

  static isEmpty(o: any): boolean {
    if (Utils.isString(o)) {
      return o === "";
    } else if (Utils.isObject(o)) {
      if (Utils.isDate(o)) {
        return Utils.isNullOrUndefined(o);
      } else {
        return Object.getOwnPropertyNames(o).length === 0;
      }
    } else if (Utils.isArray(o)) {
      return o.length === 0;
    }
    return false;
  }

  static camelCase(o: any): any {
    if (Utils.isStringWithLength(o)) {
      return o
        .replace(/\s(.)/g, ($1: string) => {
          return $1.toUpperCase();
        })
        .replace(/\s/g, "")
        .replace(/^(.)/, ($1: string) => {
          return $1.toLowerCase();
        });
    }
    return o;
  }

  static isStringWithLength(o: any): boolean {
    return Utils.isString(o) && o.length > 0;
  }

  static isArray(o: any): boolean {
    return Utils.isDefined(o) && Array.isArray(o);
  }

  static isArrayWithLength(o: any): boolean {
    return Utils.isArray(o) && o.length > 0;
  }

  static hasOwnProperty(o: any, prop: string): boolean {
    return Utils.isDefined(o) && o.hasOwnProperty(prop);
  }

  static isDateObject(o: any): boolean {
    if (Utils.isObject(o)) {
      return (
        Utils.hasOwnProperty(o, "year") &&
        Utils.hasOwnProperty(o, "month") &&
        Utils.hasOwnProperty(o, "dayOfMonth") &&
        Utils.hasOwnProperty(o, "hourOfDay") &&
        Utils.hasOwnProperty(o, "minute") &&
        Utils.hasOwnProperty(o, "second")
      );
    }
    return false;
  }

  static isValidHttpUrl(string: string): boolean {
    let url: URL;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  static getURLHostName(string: string): string {
    let url: URL;
    try {
      url = new URL(string);
    } catch (_) {
      return "";
    }
    return url.hostname;
  }

  static init(obj: { [x: string]: any }, prop: string, value?: string | number): any {
    return Utils.hasOwnProperty(obj, prop) ? obj[prop] : value;
  }

  static formatSocialSecurity(socialSecurityNumber: string): string | null {
    const lettersRegEx = /[a-zA-Z]/g;
    let formattedValue: string | null = socialSecurityNumber;
    if (!formattedValue) {
      return null;
    }
    formattedValue = formattedValue.replace(/-/g, "");
    if (lettersRegEx.test(formattedValue) && !formattedValue?.includes("XXXXX")) {
      formattedValue = formattedValue?.replace(/\D/g, "");
    }
    if (formattedValue?.length > 9) {
      formattedValue = formattedValue.replace(/^(\w\w\w)(\w{2})(\w{0,4}).*/, "$1-$2-$3");
      return formattedValue;
    } else if (formattedValue.length > 4) {
      formattedValue = formattedValue.replace(/^(\w\w\w)(\w{2})(\w{0,4}).*/, "$1-$2-$3");
    } else if (formattedValue.length > 2) {
      formattedValue = formattedValue.replace(/^(\w\w\w)(\w{0,3})/, "$1-$2");
    } else {
      formattedValue = formattedValue.replace(/^(\w*)/, "$1");
    }
    return formattedValue;
  }

  static formatPhoneNumber(phoneNumberString: string): string | null {
    let formattedValue = phoneNumberString;
    if (!formattedValue) {
      return null;
    }
    formattedValue = formattedValue?.replace(/\D/g, "");
    if (formattedValue?.length > 10) {
      formattedValue = formattedValue?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return formattedValue;
    } else if (formattedValue.length > 5) {
      formattedValue = formattedValue?.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (formattedValue.length > 2) {
      formattedValue = formattedValue?.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      formattedValue = formattedValue?.replace(/^(\d*)/, "$1");
    }
    return formattedValue;
  }

  static formatZipcode(zipcodeString: string): string {
    const formattedValue = zipcodeString;
    return zipcodeString?.length > 5 ? formattedValue.replace(/^(\d{5})(\d{4}).*/, "$1-$2") : zipcodeString;
  }

  static maskPhoneNumber(phoneNumberString: string): string | null {
    if (!Utils.isStringWithLength(phoneNumberString)) {
      return null;
    }
    const subNUm = phoneNumberString?.substring(phoneNumberString?.length - 4);
    const maskedValue = "XXX-XXX-" + subNUm;
    return maskedValue;
  }

  static maskEmail(emailString: string): string | null {
    if (!Utils.isStringWithLength(emailString)) {
      return null;
    }
    const split = emailString?.split("@");
    const splitAddress = split[1]?.split(".");
    const emailName = emailString?.substr(0, 5) + new Array(split[0].length - 5).fill("x").join("");
    const emailAddress =
      new Array(split[1]?.length - splitAddress[0]?.length).fill("x").join("") + "." + splitAddress[1];
    return emailName + emailAddress;
  }

  static removeWhiteSpace(str: any): string {
    if (!Utils.isEmpty(str) && Utils.isStringWithLength(str)) {
      const strWithoutWhiteSpace: string = str.replace(/\s/g, "");
      return strWithoutWhiteSpace;
    } else {
      return str;
    }
  }

  static convertToClassName(str: any): string {
    if (!Utils.isEmpty(str) && Utils.isStringWithLength(str)) {
      const returnStr = Utils.removeWhiteSpace(str).toLocaleLowerCase();
      return returnStr;
    } else {
      return str;
    }
  }
}
