import { AUTHENTICATION } from "../../../environments/api";
import { Hostname } from "./hostname";

//look into making read only
export const URLMAP: Map<string, number> = new Map([
  [AUTHENTICATION.BCCONNECTWEB.LOCAL, Hostname.localhost],
  [AUTHENTICATION.BCCONNECTAPP.LOCAL, Hostname.localhost],
  [AUTHENTICATION.BCCALC.LOCAL, Hostname.localhost],
  [AUTHENTICATION.BCOPT.LOCAL, Hostname.localhost],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR1, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR2, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR3, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR4, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR5, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR6, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR7, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR8, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR9, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEVPR10, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.DEV, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.STABLE, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.INT, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.STAGE, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTWEB.PROD, Hostname.bcconnect],
  [AUTHENTICATION.BCCONNECTAPP.DEV, Hostname.bccapp],
  [AUTHENTICATION.BCCONNECTAPP.INT, Hostname.bccapp],
  [AUTHENTICATION.BCCONNECTAPP.STAGE, Hostname.bccapp],
  [AUTHENTICATION.BCCONNECTAPP.PROD, Hostname.bccapp],
  [AUTHENTICATION.BCCALC.DEV, Hostname.calc],
  [AUTHENTICATION.BCCALC.STABLE, Hostname.calc],
  [AUTHENTICATION.BCCALC.INT, Hostname.calc],
  [AUTHENTICATION.BCCALC.STAGE, Hostname.calc],
  [AUTHENTICATION.BCCALC.PROD, Hostname.calc],
  [AUTHENTICATION.BCOPT.DEV, Hostname.opti],
  [AUTHENTICATION.BCOPT.STABLE, Hostname.opti],
  [AUTHENTICATION.BCOPT.INT, Hostname.opti],
  [AUTHENTICATION.BCOPT.STAGE, Hostname.opti],
  [AUTHENTICATION.BCOPT.PROD, Hostname.opti],
]);
