import { NavigationMenuItems } from "./navigation-menu-items";
import { IQueryParams } from "../../interfaces/IQueryParams";
import { Observable, of } from "rxjs";

export class NavigationTile {
  public imageSrc: string;
  public name: string;
  public className: string;
  public selected: boolean;
  public navigationMenuItems: NavigationMenuItems[];
  public backgroundColor: string;
  public featurePermission: number;
  public href: Observable<string>;
  public brand: number;
  public queryParameters: IQueryParams[];
  public featureFlag: boolean;

  constructor() {
    this.imageSrc = "";
    this.name = "";
    this.className ="";
    this.selected = false;
    this.navigationMenuItems = [];
    this.backgroundColor = "";
    this.href = of("");
    this.brand = 1;
    this.queryParameters = [
      {
        displayName: "",
        value: 0,
      },
    ];
    this.featurePermission = 0;
    this.featureFlag = true;
  }
}
