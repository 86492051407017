import { IKeyValue } from "./../../interfaces/IKeyValue";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpContext } from "@angular/common/http";
import { BYPASS_AUTH } from "./secure-interceptor";
import { Observable, share } from "rxjs";
import Utils from "../../utilities/utils";

export type IMap<T> = (item: any) => T;

@Injectable({
  providedIn: "root",
})
export class HttpService {
  public get<T>(
    url: string,
    params?: any,
    options?: any,
    headerMap?: IKeyValue[],
    bypassAuth?: boolean
  ): Observable<any> {
    return this.http.get(url, { ...options, headers: this.getHeaders(headerMap), context: new HttpContext().set(BYPASS_AUTH, bypassAuth), params}).pipe(share());
  }

  public put<T>(
    url: string,
    model: any,
    params?: any,
    headerMap?: IKeyValue[],
    bypassAuth?: boolean
  ): Observable<any> {
    return this.http.put(url, JSON.stringify(model), { headers: this.getHeaders(headerMap), context: new HttpContext().set(BYPASS_AUTH, bypassAuth), params}).pipe(share());
  }

  public post<T>(
    url: string,
    model: any,
    params?: any,
    headerMap?: IKeyValue[],
    bypassAuth?: boolean
  ): Observable<any> {
    return this.http.post(url, JSON.stringify(model), { headers: this.getHeaders(headerMap), context: new HttpContext().set(BYPASS_AUTH, bypassAuth), params}).pipe(share());
  }

  public patch<T>(
    url: string,
    params?: any,
    options?: any,
    headerMap?: IKeyValue[],
    bypassAuth?: boolean
  ): Observable<any> {
    return this.http.patch(url, { ...options, headers: this.getHeaders(headerMap), context: new HttpContext().set(BYPASS_AUTH, bypassAuth), params }).pipe(share());
  }

  public delete<T>(
    url: string,
    params?: any,
    options?: any,
    headerMap?: IKeyValue[],
    bypassAuth?: boolean
  ): Observable<any> {
    return this.http.delete(url, { ...options, headers: this.getHeaders(headerMap), context: new HttpContext().set(BYPASS_AUTH, bypassAuth), params }).pipe(share());
  }

  private getHeaders(headerMap?: IKeyValue[]): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      Accept: "application/json",
    }).set("Content-Type", "application/json");

    if (Utils.isDefined(headerMap)) {
      headerMap?.forEach((customHeader: { key: string; value: string }): void => {
        if (Utils.isStringWithLength(customHeader.value)) {
          headers = headers.set(customHeader.key, customHeader.value);
        }
      });
    }
    return headers;
  }

  constructor(private http: HttpClient) {}
}
