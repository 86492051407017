import Utils from "../../utilities/utils";

export class Error {
  public message: string;
  public code: number;

  public hasError(): boolean {
    return Utils.isStringWithLength(this.message) && this.code > 0;
  }

  constructor(options: Error) {
    this.code = Utils.init(options, "code", 0);
    this.message = Utils.init(options, "message", "");
  }
}
