import { SessionStateService } from "./../user/session-state.service";
import { Injectable } from "@angular/core";
import { Observable, of, switchMap } from "rxjs";
import Utils from "../../utilities/utils";
import { SessionUser } from "../../models/user/user";
import { NavigationOrganizationRestriction } from "../../models/navigation/navigation-organization-restriction";
import { UserPermissionId } from "../../models/permissions/feature-permissions";

@Injectable({ providedIn: "root" })
export class AuthService {
  constructor(private sessionState: SessionStateService) {
  }

  public isPermitted(
    userPermissionId: number,
    orgPermissionId: number,
    featureFlag: boolean,
    navigationOrganizationRestriction: NavigationOrganizationRestriction
  ): Observable<boolean> {
    return this.sessionState.getSessionState().pipe(
      switchMap((sessionUser) => {
        if(this.isHiddenFeature(userPermissionId)) 
        {
          return of(false);
        }
        // Check if Admin Access
        if(sessionUser.BCUserId === 1)
        {
          return of(true);
        }

        // Check if user isn't valid, if the feature is disabled, or if not permitted to view Parent item
        if(!this.isValidUser(sessionUser) || !featureFlag || this.isNotParentOnParentOrganizationFeature(sessionUser, navigationOrganizationRestriction))
        {
          return of(false);
        }

        // Return an observable checking the user's type
        return of(this.distributorUserHasPermissions(navigationOrganizationRestriction, sessionUser, userPermissionId) || this.dealerUserHasPermissions(navigationOrganizationRestriction, sessionUser, userPermissionId, orgPermissionId));
      })
    );
  }
  
  private isHiddenFeature(userPermissionId: number): boolean{
    //if feature permission is -1, it should be hidden to all users
    return userPermissionId === -1;
  }

  private isNotParentOnParentOrganizationFeature(sessionUser: SessionUser, navigationOrganizationRestriction: NavigationOrganizationRestriction): boolean {
    return sessionUser.CurrentOrganization.ParentId !== 0 && navigationOrganizationRestriction === NavigationOrganizationRestriction.Parent;
  }

  private dealerUserHasPermissions(navigationOrganizationRestriction: NavigationOrganizationRestriction, sessionUser: SessionUser, userPermissionId: number, orgPermissionId: number) {
    return (navigationOrganizationRestriction !== NavigationOrganizationRestriction.Distributor) && sessionUser.CurrentOrganization?.IsDealer
      && (sessionUser.hasPermission(userPermissionId) || sessionUser.hasOrganizationPermission(orgPermissionId, userPermissionId));
  }

  private distributorUserHasPermissions(navigationOrganizationRestriction: NavigationOrganizationRestriction, sessionUser: SessionUser, userPermissionId: number) {
    return (navigationOrganizationRestriction !== NavigationOrganizationRestriction.Dealer) && !sessionUser.CurrentOrganization?.IsDealer && sessionUser.hasPermission(userPermissionId);
  }

  private isValidUser(user: SessionUser) : boolean
  {
    return (user && Utils.isDefined(user.Permissions) && Utils.isArrayWithLength(Object.values(user.Permissions)))
  }
}
